<template>
    <div style="height: 64vh"  class="d-flex justify-content-center align-items-center">
        <h5  class="text-muted">Record not Available</h5>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>