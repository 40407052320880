<template>  
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <TheHeader />
          <div class="bg-lg" id="kt_toolbar">
            <div id="kt_toolbar_container" class="container-xxl py-5">
              <div class="row gy-0 gx-10">
                <SideBar />
                <div class="col-md-10 bg-w" @click="show=false">
                <router-view />
                </div>
              </div>
            </div>
          </div>
        <TheFooter />
      </div>
    </div>
  </div>
</template>

<script setup>
import TheHeader from '../components/layouts/TheHeader.vue'
import SideBar from '../components/layouts/SideBar.vue'
import TheFooter from '../components/layouts/TheFooter.vue' 
import {toggleSideBar} from '@/composables/toggle'
const {show} = toggleSideBar()
</script>

<style>
.cursor-pointer{
  cursor: pointer;
}
.cursor-none{
  pointer-events: none;
}
.activeTab{
    background-color: #432da630 !important;
}
.col-md-9 > .col-xl-9{
  width: 100% !important;
}
</style>
<style scoped>
.container-xxl{
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1800px){
    max-width: 1720px;
  }
  
  @media (min-width: 1400px) and (max-width: 1799px){
    max-width: 1660px;
  }
}
</style>