<template>
  <div class="d-flex flex-column flex-root">
    <div class="page d-flex flex-row flex-column-fluid">
      <div class="wrapper d-flex flex-column flex-row-fluid" id="kt_wrapper">
        <TheHeader />
          <div class="bg-lg py-5 py-lg-5" id="kt_toolbar">
            <div id="kt_toolbar_container" class="container-xxl py-5">
              <i @click="router.go(-1)" style="font-size: 20px; color: #5241a1" class="fa fa-arrow-left cursor-pointer px-5 pt-4" ></i>
              <div class="row gy-0 gx-10">
                <div class="bg-lg messages_inbox">
      <div class="container-xxl post pt-20 pb-20" id="kt_post">
        <!--begin::Layout-->
        <div class="d-flex flex-column flex-lg-row">
          <!--begin::Sidebar-->
          <!-- <ChatList @CurrentUser="CurrentUser"/> -->
          <!--end::Sidebar-->
          <!--begin::Content-->
          <ChatBox :user_info="user_info"/>
          <!--end::Content-->
        </div>
        <!--end::Layout-->
      </div>
      <!--end::Toolbar-->
    </div>
              </div>
            </div>
          </div>
        <TheFooter />
      </div>
    </div>
  </div>

  </template>
  
  <script setup>
  import ChatBox from "@/components/chat/ChatBox.vue";
  // import ChatList from "@/components/chat/ChatList.vue";
  import TheHeader from '../../components/layouts/TheHeader.vue'
  import TheFooter from '../../components/layouts/TheFooter.vue'
  import { useRouter,useRoute } from "vue-router";
  import {onMounted, ref} from 'vue'
  const router = useRouter()
  const route = useRoute()
  const user_info = ref('')
  onMounted(()=>{
    let u = JSON.parse(route.params.id)
    console.log( u )
    user_info.value={
      Company_name:u.Company_name?u.Company_name:u.account_name_frm,
      RegD_id:u.RegD_id?u.RegD_id:u.regd_id_frm,
      user_name:u.user_name
    }
  })
  // const CurrentUser=(data)=>{
  //   user_info.value= data
  // }
  </script>
  
  <style scoped>
  </style>
