<template>
  <div class="custom_options">
    <div>
      <div class="data_filter w-100 col-md-12">
      
        <div class="d-flex justify-content-between col-md-12 gap-4">
                        <div class="flex-grow-1 col-lg-3 col-md-3 col-2 mb-3">
                          <label for="" class="form-label"><b>Start Date</b></label>
                          <input
                            name="startDate"
                            type="date"
                            placeholder="Start Date"
                            v-model="formData.date_from"
                            class="form-control form-control-sm form-control-solid"
                          />
                        </div>
                        <div class="flex-grow-1 col-lg-3 col-md-3 col-2 mb-3">
                          <label for="" class="form-label"><b>End Date</b></label>
                          <input
                            name="EndDate"
                            v-model="formData.date_to"
                            type="date"
                            placeholder="End Date"
                            :max="new Date().toISOString().slice(0, 10)"
                            class="form-control form-control-sm form-control-solid"
                          />
                        </div>
                    
          <!-- <VueDatePicker v-model="date" @change="handleDateChange" range multi-calendars type="date" placeholder="Select Custom Date">
          </VueDatePicker> -->

        </div>
        <div class="d-flex gap-4">
         
          <div class="flex-grow-1 w-50">
          <Vue3MultiselectCheckboxed  
          :options="supplier_overview_option" 
          bindLabel="name" 
          bindValue="value" 
          class="w-100"
          mode="multiple"
          :hasSearch="true" 
          @onVSelect="setResult"
           />
        </div>
        <button  @click="fetchDataAndRenderChart()" class="btn btn-sm btn-primary flex-grow-1 w-50" style="height: 35px;">Submit</button>
        </div>
      
                      
      </div>
    </div>
    <div class="data_filter mb-3">
      <span data-v-1d81e2c0="" @click="calender_wise_data(subWeeks, 1)">1W</span>
      <span data-v-1d81e2c0="" @click="calender_wise_data(subWeeks, 2)">2W</span>
      <span data-v-1d81e2c0="" @click="calender_wise_data(subMonths, 1)">4W</span>
      <span data-v-1d81e2c0="" @click="calender_wise_data(subMonths, 3)">3M</span>
      <span data-v-1d81e2c0="" @click="calender_wise_data(subMonths, 6)">6M</span>
      <span data-v-1d81e2c0="" @click="calender_wise_data(subMonths, 12)">12M</span>
    </div>
  </div>
  <div id="chart">
    <apexchart type="bar" :options="options" :series="series" ref="chart"  @dataPointSelection="onBarClick" />
  </div>
</template>
<script setup>
import { ref, reactive, onMounted, watch } from 'vue';
import { useAuthStore } from '@/store/auth.store'
import { Vue3MultiselectCheckboxed } from "vue3-multiselect-checkboxed";
import { formatISO, subWeeks, subMonths, subYears } from 'date-fns';
import { useRouter } from 'vue-router';


const router = useRouter()

const result = ref([]);
const supplier_overview_option=ref([
  { value: '0', name: 'Suppliers'},
  { value: '1', name: 'Audited'},
  { value: '2', name: 'Not Audited'},
  { value: '3', name: 'Inprocess Audit'},
  { value: '4', name: 'Re Audit'}])
let chart = ref(null);
// let val_garph = ref([]);
const setResult = (val) => {
  result.value = [...val]
  formData.Column = val.map(Number);

};
const store = useAuthStore()

const onBarClick = (event, chartContext, config) => {
  console.log(config);
  if (config.dataPointIndex === 0) {
    router.push('suppliers');
  }else if(config.dataPointIndex === 1){
    router.push({path:'audit-Request' , query:{id:'3'}});
  }else if(config.dataPointIndex === 2){
    router.push({path:'audit-Request' , query:{id:'5'}});
  }else if(config.dataPointIndex === 3){
    router.push({path:'audit-Request' , query:{id:'1'}});
  }else if(config.dataPointIndex === 4) {
    router.push({path:'audit-Request' , query:{id:'4'}});

  }
};


watch(result,()=>{
 let itr =  Object.values(result.value)
 formData.Column.push(itr)

setTimeout(() => {
  fetchDataAndRenderChart()
}, 500);
})



const formData = reactive({
  date_from: '',
  date_to: '',
  Column: []
})

// let newCategories = ['Suppliers', 'Audited', 'Not Audited', 'Inprocess Aud', 'Re Audit']; // Initial categories



const calender_wise_data = (cal, id) => {
  console.log(cal)
  formData.date_from = ref(formatISO(cal(new Date(), id), { representation: 'date' }));
  formData.date_to = ref(formatISO(new Date(), { representation: 'date' }));

  fetchDataAndRenderChart()
}


const parseApiResponse = (response) => {
  const orgGraphData = response.Admin_Side_Graph;
  
  // Filter out the "chart" property from the specific object in the array
  const filteredOrgGraphData = orgGraphData.map(item => {
    if (item.chart) {
      delete item.chart;
    }
    return item;
  });

  const categories = Object.keys(filteredOrgGraphData[0]);
  const seriesData = Object.values(filteredOrgGraphData[0]);

  return { categories, seriesData };
};

watch(result,()=>{

  chart.value.updateOptions({
      xaxis: {
        categories: result.value,
        // click: onBarClick
      },
    
  });

  // newCategories = result.value
  console.log('result', result.value)
})


const fetchDataAndRenderChart = () => {
  store.getAdninOrgGraphData(formData).then((response) => {
    if (response.status === 200) {
      
      // Filter out the "chart" property from the API response
      const filteredResponse = { ...response.data };
      delete filteredResponse.chart;

      const {categories, seriesData } = parseApiResponse(filteredResponse);

      // Update the x-axis categories
      if (chart.value) {
        chart.value.updateOptions({
          xaxis: {
            categories: categories,
          },
        });
      }

      // Update the chart data
      chart.value.updateSeries([
        {
          data: seriesData.map((value) => Number(value)),
        },
      ]);
    }
  });
};


// Calculate the current date and one year ago from the current date

const originalSeriesData = [
];

// Function to calculate the maximum value in the data series
const series = reactive([
  {
    name: '',
    data: originalSeriesData.map((item) => item.value),
    // ...
  },
]);

const options = reactive({
  chart: {
    height: 410,
    type: 'bar',
  },
  plotOptions: {
    bar: {
      borderRadius: 10,
      dataLabels: {
        position: 'top', // top, center, bottom
      },
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val;
    },
    offsetY: -20,
    style: {
      fontSize: '12px',
      colors: ["#304758"]
    }
  },
  stroke: {
    width: 2
  }, grid: {
    row: {
      colors: ['#fff', '#f2f2f2']
    }
  },
  xaxis: {
    categories: [],
    // categories: categoryRoutes,
    position: 'top',
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    },
    crosshairs: {
      fill: {
        type: 'gradient',
        gradient: {
          colorFrom: '#D8E3F0',
          colorTo: '#BED1E6',
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        }
      }
    },
    tooltip: {
      enabled: true,
    }
  },
  yaxis: {
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false,
    },
    labels: {
      show: false,
      formatter: function (val) {
        return val;
      }
    },

  },
});

onMounted(() => {
  console.log(chart.value);
  formData.date_from = ref(formatISO(subYears(new Date(), 1), { representation: 'date' }));
  formData.date_to = ref(formatISO(new Date(), { representation: 'date' }));

  console.log(formData.date_from)


  fetchDataAndRenderChart()
})
</script>

<style scoped>
.data_filter span {
  background: white;
  display: inline-block;
  width: 20%;
  height: 33px;
  border: 1px solid #ddd;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 10px;
}
.custom_options>div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
<style>
 .wayfmf6wh3 {
  border: 1px solid white;
  background-color: #ffffff !important;
  color: #000000 !important;
}
.y8dw7zjbs4{
  border: 1px solid black;
  border-radius: 5px;
  width:100%
}
.y8dw7zjbs4  .wayfmf6wh3
{
  min-width: 100% !important;
}
.qf5k5qmpv5 ul  {
  background-color: #ffffff !important;
}
.qf5k5qmpv5 input+span {
  background-color: #ffffff !important;
}
.hlyi45ju8 input  {
  background-color: #ffffff !important;
  color: #000000 !important;
}
.qf5k5qmpv5 .hlyi45ju8  {
  background-color: #ffffff !important;
  
}
</style>