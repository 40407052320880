
import VueNativeSock from "vue-native-websocket-vue3";
import { useSocketStoreWithOut } from '@/store/WebSocket/useSocketStore';
import {getCurrentInstance} from 'vue'
const store = useSocketStoreWithOut();
export function WebScoketConnection (){
    const user =JSON.parse(localStorage.getItem('user'))
    const path = process.env.VUE_APP_WEB_SOCKET;
    const app = getCurrentInstance().appContext.app;
    const initilizeWebScoket = ()=>{
        // console.log(`${path}/websockets?RegD_id=${user.User.User_id}&Account_Type=${user.User.User_id}&Category_ids=0&is_admin=true`,'connection')
        app.use(VueNativeSock, `${path}/websockets?RegD_id=0&Account_Type=${user.User.User_id}&Category_ids=0&is_admin=true`,{
            store: store,
            format: 'json', 
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 3000,
            // passToStoreHandler: function (eventName, event, next) {
            //     console.log(event,'passto')
            //     // event.data = event.should_have_been_named_data
            //     // next(eventName, event)
            // }
          });
    }
   const pushMessage  =(data)=>{
    app.config.globalProperties.$socket.sendObj(data);
   }

   const checkOnline  =(data)=>{
    app.config.globalProperties.$socket.sendObj(data);
   }
    return {
        initilizeWebScoket,
        pushMessage,
        checkOnline
    }
}