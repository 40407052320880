import {ref} from 'vue'

const show = ref(false)
export function toggleSideBar() {

const toggleButton =()=>{
    show.value= !show.value
}
return{
    show,
    toggleButton  
}
}