
import { defineStore } from "pinia";
import Api from '@/services/API'
// import showToast from '@/composables/useToast.js'
// import Socket from "@/composables/webSocket/chat_websocket_send"

export const chat = defineStore({
    id: 'chat',
    state: () => ({
         chat_data:[],
         RegD_frm:'',
         RegD_to:'',
         adminList:'',
         chat_notification:[],
         admin_notification:[]
    }),
    actions: {

        async GetMessage(payload){
            await Api.get(`api/general/get-massage-data?is_admin=true&regd_id_frm=${payload.RegD_frm}&regd_id_to=${payload.RegD_to}&trans_no=${payload.trans_no}`)
            .then(response=>{
                this.chat_data=response.data
            })
         },

         async getnotification()
         {
            const rep = await Api.get('api/general/get_Chat_Alerts?RegD_id=0')
            this.chat_notification = rep.data.chat_alerts
        },

        async getHeaderNotification()
        {
           const rep = await Api.get('api/general/admin-notifications')
          this.admin_notification = rep.data
         }
        
        // async sendMessage(payload){
        //     Socket(payload)
        //         //    await Api.post('/api/general/send-massage',payload).then(response =>{
        //         //         this.GetMessage({RegD_frm:this.RegD_frm,RegD_to:this.RegD_to})
        //         //       })
        //         //       .catch(error=>{
        //         //        showToast(error.message)
        //         //       })
        //            }  
                } 
})