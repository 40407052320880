<template>
    <div class="modal fade show"  style="display:block;background:rgba(0,0,0,0.5);opacity:1 !important">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="props.tabName === 'registered_companies'">Registered Companies</h5>
                        <h5 class="modal-title" v-else>New Singups</h5>

                        <!--begin::Close-->
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"  @click="closeModel()" aria-label="Close">
                            <i class="fa fa-times" aria-hidden="true"></i>
                        </div>
                        <!--end::Close-->
                    </div>

                    <div class="modal-body">    
            <div class="row g-5 g-xl-8 organization-dashboard-firstrow">
                <div class="col-xl-4">
                    <router-link to="/organizations" v-if="props.tabName === 'registered_companies'">
                    <a href="#" >
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                            <i class="fa-solid fa-sitemap fs-3 text-black" ></i>
                            <h4 class="ml-5">Organization</h4>
                        </div>
                        <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_organizations}}</div></h4>
                    </div>

                    </a>
                </router-link>
                
                    <a href="#" v-else @click="getOwnGraph('organizations')">
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                            <i class="fa-solid fa-sitemap fs-3 text-black" ></i>
                            <h4 class="ml-5">Organization</h4>
                        </div>
                        <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_organizations}}</div></h4>
                    </div>

                    </a>

                </div>
                <div class="col-xl-4">
                    <router-link to="/suppliers" v-if="props.tabName === 'registered_companies'">
                    <a href="#" >
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                            <i class="fas fa-luggage-cart fs-3 text-black"></i>
                            <h4 class="ml-5">Suppliers</h4>
                        </div>
                        <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_suppliers}}</div></h4>
                    </div>
                    </a>
                </router-link> 
                <a href="#" v-else @click="getOwnGraph('suppliers')">
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                            <i class="fas fa-luggage-cart fs-3 text-black"></i>
                            <h4 class="ml-5">Suppliers</h4>
                        </div>
                        <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_suppliers}}</div></h4>
                    </div>
                    </a>
                </div>
                
                <div class="col-xl-4">
                    <router-link to="/freelancer" v-if="props.tabName === 'registered_companies'">
                    <a href="#" >
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                                <i class="fas fa-handshake fs-3 text-black"></i>
                            <h4 class="ml-5">Freelance</h4>
                    </div>

                            <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_freelancers}}</div></h4>
                        </div>
                    </a>    
                </router-link> 
                <a href="#" v-else @click="getOwnGraph('freelancer')">
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                                <i class="fas fa-handshake fs-3 text-black"></i>
                            <h4 class="ml-5">Freelance</h4>
                    </div>

                            <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_freelancers}}</div></h4>
                        </div>
                    </a>  
                </div>
                <div class="col-xl-4">
                   <router-link to="/audit-firms" v-if="props.tabName === 'registered_companies'">
                    <a href="" >
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                                <i class="fas fa-user-check fs-3 text-black"></i>
                            <h4 class="ml-5">Auditor Firms</h4>
                            </div>
                            <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_auditfirms}}</div></h4>
                        </div>
                    </a>
                   </router-link> 
                   <a href="#" v-else @click="getOwnGraph('audit-firms')">
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                                <i class="fas fa-user-check fs-3 text-black"></i>
                            <h4 class="ml-5">Auditor Firms</h4>
                            </div>
                            <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_auditfirms}}</div></h4>
                        </div>
                    </a>
                </div>


                <div class="col-xl-4">
                    <router-link to="/regulators" v-if="props.tabName === 'registered_companies'">
                    <a href="#" >
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                            <i class="far fa-registered fs-3 text-black"></i>
                            <h4 class="ml-5">Regulator</h4>
                        </div>
                            <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_regulators}}</div></h4>
                        </div>
                    </a>
                  </router-link> 
                  <a href="#" v-else @click="getOwnGraph('regulators')">
                        <div class="d-flex justify-content-between badge badge-light fw-bolder pt-4 pb-1">
                            <div class="d-flex">
                            <i class="far fa-registered fs-3 text-black"></i>
                            <h4 class="ml-5">Regulator</h4>
                        </div>
                            <h4><div class="text-black fw-bolder">{{d.DashboardData?.active_regulators}}</div></h4>
                        </div>
                    </a>
                </div>
                
               
            </div>
                      
                    </div>

<div style="padding:30px 20px" v-show="props.tabName !== 'registered_companies'">
  <div class="d-flex justify-content-between gap-4">
 <div class="flex-grow-1">
  <label for="" class="form-label">Start Date</label>
  <input  name="startDate" type="date" placeholder="Start Date" :max="new Date().toISOString().slice(0, 10)" class="form-control form-control-lg form-control-solid" />
 </div>
  <div  class="flex-grow-1">
  <label for=""  class="form-label">End Date</label>
  <input  name="EndDate" type="date" placeholder="End Date" :max="new Date().toISOString().slice(0, 10)" class="form-control form-control-lg form-control-solid" />
</div>
</div>
<div class="modal-footer" v-if="patnerName">
    <router-link :to="`${patnerName}`" ><button type="button" class="btn btn-primary" >{{patnerName}}</button></router-link> 
  </div>
<div  style="margin:20px auto 20px; width:95%;box-shadow: 2px 2px rgba(0,0,0,0.2)">
  <CanvasJSChart :options="options" :style="styleOptions" @chart-ref="chartInstance"/>
</div>
 

  </div>


                    <div class="modal-footer d-flex justify-content-between">
                        <h5>Powerd by GlassHUB</h5>
                        <button type="button" class="btn btn-primary" @click="closeModel()">Close</button>
                    </div>
                    
                </div>
                
            </div>
        </div>

   

</template>

<script setup>
import { defineEmits, ref,defineProps,computed,onMounted } from 'vue' 
import {useAuthStore} from '@/store/auth.store';
const store = useAuthStore()
const emit = defineEmits(['closeModel']);
const props = defineProps(["tabName"])
let chart = ref(null);
let patnerName = ref('')
const closeModel = () => {
    emit('closeModel', false)
}


const getOwnGraph = ((name)=>{
    patnerName.value = name.charAt(0).toUpperCase() + name.slice(1);
})

 const options= {
      animationEnabled: true,
 
  
      toolTip: {
        shared: true
      },
      data: [{
        type: "column",
        color: "#47acb1",
        dataPoints: [
          { label: "Mon", y: 30 },
          { label: "Tue", y: 70 },
          { label: "Wed", y: 81 },
          { label: "Thur", y: 93 },
          { label: "Fri", y: 104 },


        ]
      },{
        color: "#f07113",
        dataPoints: [
          { label: "Organization", y: 100000 },
          { label: "Suppliers", y: 142000 },
          { label: "Freelance", y: 165000},
          { label: "Auditor Firms", y: 198000 },
          { label: "Regulator", y: 164000 },
        ]
      }]
    }
const d = computed(()=>{
    return store.dashboard_data
})

onMounted(()=>{
    store.GetDashboardData()
})
 const styleOptions = {
     width: "100%",
     height: "400px"
   }

   const chartInstance = (c)=> {
       chart.value = c;
 }

</script>