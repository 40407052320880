<template>
  <div
    class="menu-item"
    v-for="(item, index) in menuItems"
    :key="'sidebar_' + index"
  >
    <SideBarItem :item="item"></SideBarItem>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { items } from "@/assets/sidebar.json";
import { useadminUsersStore } from "@/store/adminUsers.store";
import SideBarItem from "./SideBarItem.vue";

const adminStore = useadminUsersStore();
const sidebarMenuItems = computed(() => adminStore.SideBarMenuList ?? []);

const findMenuItem = (itemPath = '/') => {
  const pathWithoutId = String(itemPath ?? '').replace(/\/\d+$/, '');

  if (pathWithoutId.trim().length === 0) {
    return false;
  }

  return sidebarMenuItems.value.find(
    (el => el.Menue_location.includes(pathWithoutId) && true === el.Can_view)
  );
}

const menuItems = computed(() => {
  const authedItems = [];
console.log(items);

  for(let rootItem of items) {
    const childItems = (rootItem?.children ?? []);
    const rootLocation = String(rootItem?.Menue_location ?? '');
    const foundItem = !!findMenuItem(rootLocation);
    const authedChildren = [];

    if (true === foundItem || rootLocation.includes('dashboard')) {
      authedItems.push(rootItem);
      continue;
    }

    if (childItems.length > 0) {
      for(let subItem of childItems) {
        const foundItem = !!findMenuItem(subItem?.Menue_location);

        if (true === foundItem) {
          authedChildren.push(subItem);
        }
      }

      if (authedChildren.length > 0) {
        rootItem.children = authedChildren;
        authedItems.push(rootItem);
      }
    }
  }

  return authedItems;
});

onMounted(async () => {
  try {
    await adminStore.getMenuByRoleWise();
  } catch(err) {
    alert('Ubable to load user role access levels, please reload the page!');
  }
});
</script>
