<template>             
    <div  @click="showheaderManu('NSection')" class="d-flex align-items-center ms-3 ms-lg-2">               
                <div  class="btn btn-icon btn-active-light btn-active-color-primary btn-outline btn-outline-secondary w-30px h-30px w-lg-40px h-lg-40px position-relative menu-dropdown" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                    <span class="badge badge-light bg-danger" v-if="alerts.total_notifications>0" style="position: absolute;top: -13px;padding: 6px;color: white;left: 21px;">
                     {{alerts.total_notifications }}</span>
                    <span class="svg-icon svg-icon-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path opacity="0.3"
                  d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
                  fill="black">
                </path>
                <path
                  d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
                  fill="black">
                </path>
              </svg>
            </span>
         
                </div>
                <!--begin::Menu-->
             <div id="NSection" class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
                    <div class="container topnotificationbar">
                        <div class="row heading">
                            <div class="col-md-12">
                                <span class="notification_title fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1">Alerts</span>
                            </div>
                        </div>
                    </div>
                    <ul class="head_notification_alerts_list">
                        <template v-if="alerts?.notifications?.length>0">
                        <li v-for="(list,index) in alerts.notifications" :key="index">
                            <template  v-if="list.type=='Disputes'">
                                <h6 v-if="index==0" class="title"><b>Dispute</b></h6>
                                <router-link :to="list.location" class="active cursor-pointer d-block link">
                         
                                <div class="d-flex align-items-center">
                                    <!-- <div class="symbol symbol-50px me-5">
                                        <img :src="Path+list.Company_logo" class="" alt="">
                                    </div> -->
                                    <div class="flex-grow-1">
                                   
                                       <div class="row">
                                        <div class="col-9">
                                            <span class="text-muted  me-2 fw-bold"><b>Raised By</b> :{{list.RaisedBy_name}}</span>
                                        </div>
                                        <div class="col-3 text-right">
                                                <label class="text-muted  me-2 fw-bold" :class="list.Dispute_Pro_name">{{list.Dispute_Pro_name}}</label>
                                            </div>
                                      
                                    </div>
                                       <div class="row">
                                        <div class="col-9">
                                        <span class="text-muted fw-bold"><b>Raised Against</b> :{{list.RaisedAgnst_name}}</span>
                                       </div>
                                           
                                       <div class="col-3 text-right">
                                            <small class="text-muted  fw-bold">{{list.dispute_date.substring(0,10)}}</small>
                                           </div>
                                           
                                        </div>
                                    </div>
                                    <!-- <div class="flex-grow-1 text-right">
                                    </div> -->
                                </div>
                            </router-link>
                            </template>
                        </li>
                    </template>
                    <li v-else class="no_noti">
                         No Notifications Available
                    </li>
                    </ul>
                </div>
            </div>  
</template>
<script setup>
import {ref,onMounted,computed, watch} from 'vue'
import {chat} from '@/store/chat'
import {storeToRefs} from 'pinia'
import {messages} from '@/composables/ReceiveMassage'

const store = chat()
const alerts = ref({})
const {admin_notification} = storeToRefs(store)
const {alarts_data}  = messages()


const socket_alerts = computed(()=>{
    return alarts_data.value
})

watch(socket_alerts,()=>{
    console.log(socket_alerts.value,'dispute')
    alerts.value = socket_alerts.value
})

watch(admin_notification,()=>{
    alerts.value = admin_notification.value
})

document.onclick = function(){
   let ele =  document.getElementsByClassName('showheadermanu')
   for(let x of ele)
   {
    x.classList.remove('showheadermanu')
   }
}

const showheaderManu =(e)=>{
setTimeout(()=>{
let ele = document.getElementById(e)
ele.classList.add('showheadermanu')
},100) 
}


onMounted(()=>{
  store.getHeaderNotification()
})

</script>
<style scoped>
span {
    font-size: 12px !important;
}
span b{
    width: 90px !important;
    display: inline-block !important;
}
small{
    font-size: 9px !important;
}
label{
    padding: 1px 6px;
    margin: 0 !important;
    border-radius: 10px;
    font-size: 9px;
}
.Low{
    background: rgb(0, 78, 0);
    color:#fff !important;
}
.Medium{
    background: rgb(0, 43, 128);
    color:#fff!important
}
.High{
    background: rgb(128, 115, 0);
    color:rgb(18, 18, 18)!important
}
.Urgent{
    background: rgb(128, 0, 0);
    color:#fff!important
}
.link{
    padding: 10px 20px !important;
}
.title{
    font-size: 11px;
    padding: 8px 16px 0px;
    color: grey;
    font-weight: normal;
}
.head_notification_alerts_list{
    max-height: 400px;
    overflow-y: auto;
}
    .showheadermanu{
  position:absolute !important;
  top:50px !important; 
}
.no_noti{
    padding: 10px;
    text-align: center;
    color: #a0a3a6;
}
    .changeLanguge{
    width: fit-content !important;
    font-size: 11px;
    color: #5241a1;
    padding: 2px;
    border: 1px dashed #E4E6EF;
  }
.language{
    position: absolute;
    top: 44px;
    right:0px
  }
.lang{
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.lang>span{
    cursor: pointer;
    font-weight: bold;
  }
  .activeL{
    color: #5241a1;
    position: relative;
  
}
 .activeL::before{
  position: absolute;
    left: -12px;
    top: 63%;
    height: 34%;
    width: 2px;
    background-color: #5241a1;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}
.activeL::after {
   position: absolute;
    left: -12px;
    bottom: 0;
    height: 2px;
    width: 17px;
    background-color: #336699;
    content: "";
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}
.showheadermanu{
  display: block;
  position: absolute;
  top:50px;
  right: 5px;
}
.container-xxl{
  @media (min-width: 1800px){
    max-width: 1720px;
  }
}
</style>
