import { useRoute } from "vue-router";

export default function permission() {
const route = useRoute();

let getRoles = JSON.parse(localStorage.getItem('roles_menu'))
const currentPath = route.path;
const pathWithoutId = currentPath.replace(/\/\d+$/, '');
let findMenu = getRoles?.find((el => el.Menue_location == pathWithoutId.slice(1)))

    return{
        findMenu,
    }
}