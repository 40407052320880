<template>
  <div id="chart">
    <apexchart type="bar" height="410" :options="options" :series="series" ref="chart" />
  </div>
</template>
<script setup>
import { ref , onMounted } from 'vue';
import { useregistrationsStore } from '@/store/registrationsList.store'
let chart = ref(null);
const store = useregistrationsStore()
const ConpanyName = ref('')
const Review = ref('')
const CompleteAudited = ref('')

const fetchDataAndRenderChart =  () => {
  store.getOrgReviewGraphData()
    .then(response => {
      if(response.status == 200){
        const responseData = response.data.Admin_Review_Graph;
        
        console.log(responseData);
        ConpanyName.value = responseData.map(item => item?.Company_name);
        Review.value = responseData.map(item => parseFloat(item.reviews).toFixed(2));
        CompleteAudited.value = responseData.map(item => parseFloat(item.complete_audited).toFixed(2));
  
        series.value = [{
          name: 'Reviews',
          data: Review.value,
        },
        {
        name: 'Complete Audit',
        data: CompleteAudited.value,
      }
      ];

        // Update the x-axis categories
        options.value.xaxis.categories = ConpanyName.value;

        // Render the updated chart
        chart.value.updateOptions(options.value);
      }
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
};

const series = ref([
  {
    name: '',
    data: []
  },
]);

const options = ref({
  chart: {
    height: 410,
    type: 'bar',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '50%',
      endingShape: 'rounded'
    },
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent']
  },

  xaxis: {
    categories:ConpanyName.value,
  },
  yaxis: {
    title: {
      text: ''
    },
    labels: {
      formatter: function (value) {
        // Format Y-axis labels with two decimal places
        return parseFloat(value).toFixed(2);
      }
    },
  },
  fill: {
    opacity: 1
  },
});

onMounted(() => {
  fetchDataAndRenderChart()
})

</script>

<style scoped></style>