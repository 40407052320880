<template>
  <div class="flex-lg-row-fluid ms-lg-5 ms-xl-5">
    <div class="card" id="kt_chat_messenger">
       <!--begin::Title-->
       <div class="card-title">
            <!--begin::User-->
            <div class="d-flex justify-content-center flex-column me-3">
              <a
                href="#"
                class="
                  fs-4
                  fw-bolder
                  text-gray-900 text-hover-primary
                  me-1
                  mb-2
                  lh-1
                "
                >{{props.user_info.Company_name}} <small v-if="props.user_info.user_name">({{props.user_info.user_name}})</small></a
                >
               
              <!--begin::Info-->
              <!-- <div class="mb-0 lh-1" v-if="props.user_info.status && props.user_info.status!= 'offline'">
                <span
                  class="badge badge-success badge-circle w-10px h-10px me-1"
                ></span>
                <span class="fs-7 fw-bold text-muted">Active</span>
              </div> -->
              <!--end::Info-->
            </div>
            <!--end::User-->
          </div>
          <!--end::Title-->
                        <div class="card-body"  id="kt_chat_messenger_body">
                            <div class="scroll-y me-n5 p-5 max-h-350" id="chat_box" >
                                <!-- <button @click="scrollToBottom()">bottom</button> -->
                                <template v-for="(list,index) in data_list" :key="index">
                                  <div class="d-flex justify-content-end mb-5" v-if="list.regd_id_frm==null">
                                    <div class="d-flex flex-column align-items-end">
                                        <div class="d-flex align-items-center mb-1">
                                            <div class="me-3">
                                                <span class="text-muted fs-7 mb-1">{{formatDate(list.massage_datetime)}} - {{getTime(list.massage_datetime)}}</span>
                                                <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1">You</a>
                                            </div>
                                            <div class="symbol symbol-35px symbol-circle">
                                                <img alt="Pic" :src="`${image_path}${list.user_image}`">
                                            </div>
                                        </div>
                                        <div class="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end" data-kt-element="message-text">{{list.massage}}</div>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-start mb-5" v-else>
                                    <div class="d-flex flex-column align-items-start" v-if="list.regd_id_frm==user_info.RegD_id">
                                        <div class="d-flex align-items-center mb-1">
                                            <div class="symbol symbol-35px symbol-circle">
                                                <img alt="Pic" :src="`${image_path}${list.user_image}`">
                                            </div>
                                            <div class="ms-3">
                                                <a href="#" class="fs-5 fw-bolder text-gray-900 text-hover-primary me-1">{{list.user_name_frm}}</a>
                                                <span class="text-muted fs-7 mb-1">{{formatDate(list.massage_datetime)}} - {{getTime(list.massage_datetime)}}</span>
                                            </div>
                                        </div>
                                        <div class="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start" data-kt-element="message-text">{{list.massage}}</div>
                                    </div>
                                </div>
                               
                            </template>
                            </div>
                        </div>
                         <!--begin::Card footer-->
  <div class="card-footer pt-4" id="kt_chat_messenger_footer">
   
    <form @submit.prevent="sendMessage()" style="width: 100%;">
                    <label class="form-label text-left">Type Message</label>
                    <input name="" v-model="formdata.massage" class="mb-2 form-control form-control-lg form-control-solid">
                    <button type="submit" class="btn d-block  btn-primary" style="margin-left:auto">Send</button>
    </form>
  </div>
  <!--end::Card footer-->
      </div>
  </div>
  
</template>

<script setup>
  import {chat} from '@/store/chat'
import { onMounted,computed,watch,defineProps,reactive,ref} from 'vue'
  import {messages} from '@/composables/ReceiveMassage'
  import {WebScoketConnection} from '@/composables/WebScoket'
  const {pushMessage} = WebScoketConnection()
  const {received_message}  = messages()  

const props = defineProps(['user_info'])
const image_path =ref(process.env.VUE_APP_Images)
const user =JSON.parse(localStorage.getItem('user'))
const store = chat()
const data_list = computed(()=>{
  return store.chat_data
})



watch(received_message,()=>{
    data_list.value.push(received_message.value)
})

watch(props,()=>{
  store.GetMessage({
    RegD_frm:0,
    RegD_to:props.user_info.RegD_id,
    trans_no:props.user_info?.trans_no || 'null' 
})
})

watch(store,()=>{
setTimeout(() => {
    scrollToBottom()
});
})


const scrollToBottom=()=>{
const element = document.getElementById('chat_box');
element.scrollTop = element.scrollHeight;
}

const getTime =(val)=>{
  var event =  new Date(val).toLocaleString('en-US', {
                  timeStyle: 'short',
                  hourCycle: 'h12',
                })
    return event
}

const sendMessage= ()=>{
    let data = props.user_info
    formdata.massage_datetime = new Date();
    formdata.regd_id_frm =0;//user.User.User_id;
    formdata.regd_name_frm = user.User.User_fullname;
    formdata.regd_id_to = data.RegD_id;
    formdata.regd_name_to =data.Company_name;
    formdata.regm_id_frm=0
    formdata.regm_name_frm='null'
    formdata.regm_id_to = data.RegM_id
    formdata.regm_name_to ='null'
    formdata.user_id_frm = user.User.User_id
    formdata.user_name_frm = user.User.User_fullname
    formdata.created_by =user.User.User_id
    formdata.is_admin = true
    formdata.account_id_frm =0
    formdata.account_name_frm ='null'
    formdata.account_id_to = data.Account_type
    formdata.account_name_to =data.Account_type_name
    formdata.trans_no = data?.trans_no || 'null'
    formdata.trans_path = data?.trans_no?'project':'null'
    if(formdata.massage!=''){
        // store.sendMessage(formdata)
        pushMessage(formdata) 
        formdata.massage=''
    }
}
  
const formdata = reactive({
    type: "Chat",
    massage:'',
    massage_datetime:'',
    regd_id_frm:'',
    regd_name_frm:'',
    regd_id_to:'',
    regd_name_to:'',
    regm_id_frm:'',
    regm_name_frm:'',
    regm_id_to:'',
    regm_name_to:'',
    user_id_frm:'',
    user_name_frm:'',
    created_by:'',
    is_admin:'',
    account_id_frm:'',
    account_name_frm:'',
    account_id_to:'',
    account_name_to:'',
    trans_no:'',
    trans_path:''
})


function formatDate(inputDate) {
  let date = inputDate.substring(0,10)
  const months = [
    "JAN", "FEB", "MAR", "APR",
    "MAY", "JUN", "JUL", "AUG",
    "SEP", "OCT", "NOV", "DEC"
  ];

  const dateParts = date.split("-");
  const year = dateParts[0];
  const month = months[parseInt(dateParts[1]) - 1];
  const day = dateParts[2];

  return `${day} ${month} ${year}`;
}


onMounted(()=>{
store.chat_data =[]
})

</script>

<style scoped>
  .card-title >div{
    padding: 30px 30px;
  }
</style>