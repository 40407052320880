export default function useHelper() {
  function showDate(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = dateObj.getUTCDate();
    const month = monthNames[dateObj.getUTCMonth()];
    const year = dateObj.getUTCFullYear();

    return `${month}, ${day}, ${year}`;
  }

  function showTime(dateTimeString) {
    const dateObj = new Date(dateTimeString);
    let hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const suffix = hours >= 12 ? "PM" : "AM";

    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${suffix}`;

    return formattedTime;
  }

  function useCurrancyFormater(number) {
    number = Number(number);
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    if (number) return `${number.toLocaleString(undefined, options)} SAR`;
    else {
      number = 0;
      return `${number.toLocaleString(undefined, options)} SAR`;
    }
  }

  function feeCalculation(amount_inc_vat, { vat, commission }) {
    let paymentCalculation = {
      audit_amount: 0,
      auditFirmTotalAmount: 0,
      commission_percentage: 0,
      commission_amount: 0,
      commission_vat_amount: 0,
      vat_amount: 0,
      vat_percentage: 0,
      total: 0,
    };
    paymentCalculation.audit_amount =
      amount_inc_vat - (amount_inc_vat * commission) / 100;

    paymentCalculation.vat_percentage = vat;

    paymentCalculation.vat_amount =
      (paymentCalculation.audit_amount * paymentCalculation.vat_percentage) /
      100;

    paymentCalculation.commission_percentage = commission;

    paymentCalculation.commission_amount =
      (amount_inc_vat * paymentCalculation.commission_percentage) / 100;

    paymentCalculation.commission_vat_amount =
      (paymentCalculation.commission_amount *
        paymentCalculation.vat_percentage) /
      100;

    paymentCalculation.auditFirmTotalAmount =
      paymentCalculation.audit_amount +
      (paymentCalculation.audit_amount * paymentCalculation.vat_percentage) /
        100;

    paymentCalculation.total =
      paymentCalculation.audit_amount +
      paymentCalculation.vat_amount +
      paymentCalculation.commission_amount +
      paymentCalculation.commission_vat_amount;

    return paymentCalculation;
  }
  return {
    showDate,
    showTime,
    useCurrancyFormater,
    feeCalculation,
  };
}
