import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: "smooth", 
  });

  const isAuthenticated = localStorage.getItem('auth');

  if (['/login'].includes(to.path) || isAuthenticated) {    
    const currentPath = to.path;
    const pathWithoutId = currentPath.replace(/\/\d+$/, '');
    let menus = JSON.parse(localStorage.getItem('roles_menu'))

    let findMenu = menus?.find((el => el.Menue_location == pathWithoutId.slice(1)))


    if(findMenu != undefined && !findMenu?.Can_view && findMenu?.Menue_location != 'dashboard'){
      router.push(from.path)  
    }

    next(); // allow to enter route
  } else {
    if (['/footer'].includes(to.path)){
      next();
    }else{
     next('/login'); // go to '/login';
    } 
  }
});

export default router;
