<template>
  <div class="collapse-wrapper" v-if="item.children && item.is_parent">
    <a @click="toggleCollapse" class="menu-link py-3 d-flex">
      {{ item.parent }}
      <i v-if="isCollapsed" class="fa fa-chevron-left px-2 ml-auto"></i>
      <i v-else-if="!isCollapsed" class="fa fa-chevron-down px-2 ml-auto"></i>
    </a>

    <div v-if="!isCollapsed" class="children">
      <a
        v-for="child in item.children"
        @click="goToRoute(`/` + child.Menue_location)"
        class="menu-link py-3"
        :class="{ activeTab: currentPath.includes(child.Menue_location) }"
        :key="'child' + child.Menue_id">
        {{ child.Menue_label }}
      </a>
    </div>
  </div>
  <div class="single" v-else>
    <a
      @click="goToRoute(`/` + item.Menue_location)"
      class="menu-link py-3"
      :class="{ activeTab: currentPath.includes(item.Menue_location) }"
    >
      {{ item.Menue_label }}
    </a>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";

import { useRoute, useRouter } from "vue-router";
import { toggleSideBar } from "@/composables/toggle";

const route = useRoute();

const router = useRouter();

const { toggleButton } = toggleSideBar();

const props = defineProps(["item"]);

const currentPath = computed(() => route.path);

const item = ref(props["item"]);

const isCollapsed = ref(true);

const goToRoute = (path) => {
  if (path == "/new-signup") {
    window.location.href = "/new-signup";
  } else {
    router.push({ path: path });
  }
  toggleButton();
};

const toggleCollapse = (e) => {
  e.preventDefault();
  if (isCollapsed.value) {
    e.target.classList.add("active");
  } else {
    e.target.classList.remove("active");
  }
  isCollapsed.value = !isCollapsed.value;
};

const updateIsCollapse = () => {
  if (currentPath.value.includes(item.value?.Menue_location)) {
    isCollapsed.value = false;
  }

  item.value.children?.forEach((child) => {
    console.log(" loop");

    if (currentPath.value.includes(child?.Menue_location)) {
      isCollapsed.value = false;
    }
  });
};
onMounted(() => {
  updateIsCollapse();
});
</script>
<style scoped>
.menu-item .menu-item {
  padding-left: 20px;
}

.children {
  padding-left: 20px;
}
.menu-link {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
}

.activeTab {
  background-color: rgb(114, 57, 234) !important;
  color: #fff !important;
}
</style>
