import { useAuditRequestStore } from '@/store/audit.store';
import API from './API';

export default async function getAuditReportTemplate(reportId, regdId) {
  try {
    if (isNaN(reportId)) {
      throw new Error('Invalid report ID');
    }

    const auditStore = useAuditRequestStore();
    const report = await auditStore.loadAuditReport(reportId, regdId);
    const complianceId = report.data.audit_report[0].complaince_id;
    const complianceResponse = await API.get(
      `api/compliance/get-by-id?Compliance_id=${complianceId}&report_id=${reportId}`
    );

    const regDId = complianceResponse.data.data.compliance.RegD_id;
    const templatesResponse = await API.get(
      `api/audit/get-organization-auditreport-templates?RegD_id=${regDId}`
    );

    if (templatesResponse.status === 200 && Array.isArray(templatesResponse.data)) {
      const template = templatesResponse.data.filter(_ => _.Compliance_id == complianceId)?.pop();
      return template;
    } else {
      throw new Error('Template not found');
    }
  } catch (error) {
    console.error('Error fetching audit report template:', error);
    return null;
  }
}