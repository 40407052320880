import { defineStore } from 'pinia'
import axios from 'axios'
import API from '@/services/API'
import useToast from '@/composables/useToast.js'
export const profileStore = defineStore('profile',{
    state:()=>({
        profileDetail:[],
        btnLoading:false
    }),

    actions:{
      async getProfile(payload){
       
        const response = await  API.get('api/profile/profile-by-id?User_id='+ payload)
        if(response.status==200){
          this.profileDetail = response.data
        }else{
            useToast(response)
        }
      },
    async updateProfile (payload){
      this.btnLoading=true
      const apiBaseURL = process.env.VUE_APP_BASE_URL;
      const TOKEN = localStorage.getItem('TOKEN') || null;
      axios.defaults.headers.common['x-access-token']=TOKEN
      const response = await  axios.post(apiBaseURL+'/api/profile/update-user-profile',payload)
      if(response.status==200){
        let user =  JSON.parse(localStorage.getItem('user'))
        this.getProfile(user?.User?.User_id)
        this.btnLoading=false
        useToast('profile update successfully','success')
        
      }else{
         this.btnLoading=false
          useToast(response)   
      }
    }
    }
})