import {ref} from 'vue'
const received_message = ref('')
const notifications_data = ref([])
const alarts_data = ref('')
const online = ref ('')
const adminList = ref([])
    export function messages (){
    const ReceiveMessage =(data)=>{
        received_message.value=data
    
    }
    const online_offine =(data)=>{
        online.value = data
    }    

    const Receive_notifications=(data)=>{
        notifications_data.value = data
    }    

    const Receive_alerts=(data)=>{
        notifications_data.value=data
    }   

    const GetAlerts=(data)=>{
        alarts_data.value=data
    }   

    const Receive_adminList =(data)=>{
        adminList.value = data
    }
    

     return {
        ReceiveMessage,
        received_message,
        online_offine,
        Receive_notifications,
        online,
        notifications_data,
        Receive_alerts,
        alarts_data,
        adminList,
        Receive_adminList,
        GetAlerts
    }
}